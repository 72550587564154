import { Close } from '@mui/icons-material';
import { Typography, IconButton, Stack, TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText, Box, FormGroup, FormControlLabel, Checkbox, Button, CircularProgress, Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
export interface EditProviderDetailsModalProps {
  editProviderDetailsModalVisible: boolean;
  setEditProviderDetailsModalVisible: (value: boolean) => void;
  onSubmitEditProviderDetailsBtnClick: () => unknown;
  cardConfigFields: any;
  editProviderFormData: EditProviderDetailsModalFormData;
  updateEditProviderDetailsForm: (fieldName: string, value: string | boolean) => void;
  currentProviderDetails: EditProviderDetailsModalFormData;
  loading: boolean;
  isEditingProviderDetails: boolean;
}
export interface EditProviderDetailsModalFormData {
  providerId: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  npi: string;
  caqhProviderId: string;
  providerType: string;
  reason: string;
  acceptedTos: boolean;
}
const EditProviderDetailsModal: React.FC<EditProviderDetailsModalProps> = ({
  editProviderDetailsModalVisible,
  setEditProviderDetailsModalVisible,
  onSubmitEditProviderDetailsBtnClick,
  cardConfigFields,
  editProviderFormData,
  updateEditProviderDetailsForm,
  currentProviderDetails,
  loading,
  isEditingProviderDetails
}) => {
  const isValid = (formData: EditProviderDetailsModalFormData) => {
    return formData.acceptedTos && formData.firstName && formData.lastName && formData.providerType && formData.reason;
  };
  return <Dialog open={editProviderDetailsModalVisible} onClose={() => setEditProviderDetailsModalVisible(false)} data-sentry-element="Dialog" data-sentry-component="EditProviderDetailsModal" data-sentry-source-file="EditProviderDetailsModal.tsx">
      <form>
        <DialogTitle sx={{
        borderBottom: 'solid 1px rgba(0,0,0,.2)'
      }} data-sentry-element="DialogTitle" data-sentry-source-file="EditProviderDetailsModal.tsx">
          <Typography variant="h5" data-sentry-element="Typography" data-sentry-source-file="EditProviderDetailsModal.tsx">Edit Provider Details</Typography>
          <Typography variant="body1" data-sentry-element="Typography" data-sentry-source-file="EditProviderDetailsModal.tsx">
            Please edit provider details below
          </Typography>
          <IconButton onClick={() => setEditProviderDetailsModalVisible(false)} sx={{
          position: 'absolute',
          top: 30,
          right: 20
        }} data-sentry-element="IconButton" data-sentry-source-file="EditProviderDetailsModal.tsx">
            <Close fontSize="small" data-sentry-element="Close" data-sentry-source-file="EditProviderDetailsModal.tsx" />
          </IconButton>
        </DialogTitle>
        <DialogContent data-sentry-element="DialogContent" data-sentry-source-file="EditProviderDetailsModal.tsx">
          <Stack gap={2} component="form" marginTop={4} marginBottom={2} data-sentry-element="Stack" data-sentry-source-file="EditProviderDetailsModal.tsx">
            <TextField fullWidth variant="filled" required={true} key={cardConfigFields?.firstName?.label} label={cardConfigFields?.firstName?.label} name={cardConfigFields?.firstName?.label} value={editProviderFormData.firstName} onChange={e => updateEditProviderDetailsForm('firstName', e.target.value)} helperText={`Current Value: ${currentProviderDetails.firstName || ''}`} data-sentry-element="TextField" data-sentry-source-file="EditProviderDetailsModal.tsx" />{' '}
            <TextField fullWidth variant="filled" key={cardConfigFields?.middleName?.label} label={cardConfigFields?.middleName?.label} name={cardConfigFields?.middleName?.label} value={editProviderFormData.middleName} onChange={e => updateEditProviderDetailsForm('middleName', e.target.value)} helperText={`Current Value: ${currentProviderDetails.middleName || ''}`} data-sentry-element="TextField" data-sentry-source-file="EditProviderDetailsModal.tsx" />
            <TextField fullWidth variant="filled" required={true} key={cardConfigFields?.lastName?.label} label={cardConfigFields?.lastName?.label} name={cardConfigFields?.lastName?.label} value={editProviderFormData.lastName} onChange={e => updateEditProviderDetailsForm('lastName', e.target.value)} helperText={`Current Value: ${currentProviderDetails.lastName || ''}`} data-sentry-element="TextField" data-sentry-source-file="EditProviderDetailsModal.tsx" />
            <TextField fullWidth variant="filled" key={cardConfigFields?.npi?.label} label={cardConfigFields?.npi?.label} name={cardConfigFields?.npi?.label} value={editProviderFormData.npi} onChange={e => updateEditProviderDetailsForm('npi', e.target.value)} helperText={`Current Value: ${currentProviderDetails.npi || ''}`} data-sentry-element="TextField" data-sentry-source-file="EditProviderDetailsModal.tsx" />
            <TextField fullWidth variant="filled" key={cardConfigFields?.caqhProviderId?.label} label={cardConfigFields?.caqhProviderId?.label} name={cardConfigFields?.caqhProviderId?.label} value={editProviderFormData.caqhProviderId} onChange={e => updateEditProviderDetailsForm('caqhProviderId', e.target.value)} helperText={`Current Value: ${currentProviderDetails.caqhProviderId || ''}`} data-sentry-element="TextField" data-sentry-source-file="EditProviderDetailsModal.tsx" />
            <FormControl data-sentry-element="FormControl" data-sentry-source-file="EditProviderDetailsModal.tsx">
              <InputLabel id={'providerTypeLabel'} required={true} data-sentry-element="InputLabel" data-sentry-source-file="EditProviderDetailsModal.tsx">
                {cardConfigFields?.providerType?.label}
              </InputLabel>
              <Select fullWidth variant="filled" aria-labelledby={'providerTypeLabel'} key={cardConfigFields?.providerType?.label} name={cardConfigFields?.providerType?.label} value={editProviderFormData.providerType} onChange={e => updateEditProviderDetailsForm('providerType', e.target.value)} data-sentry-element="Select" data-sentry-source-file="EditProviderDetailsModal.tsx">
                {cardConfigFields?.providerType?.options?.map((item: string, i: number) => {
                return <MenuItem key={i.toString()} value={(item as any)}>
                        {item.toString()}
                      </MenuItem>;
              })}
              </Select>
              <FormHelperText data-sentry-element="FormHelperText" data-sentry-source-file="EditProviderDetailsModal.tsx">
                {`Current Value: ${currentProviderDetails.providerType || ''}`}
              </FormHelperText>
            </FormControl>
            <Box sx={{
            mt: '1.5rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '1.5rem'
          }} data-sentry-element="Box" data-sentry-source-file="EditProviderDetailsModal.tsx">
              <FormGroup data-sentry-element="FormGroup" data-sentry-source-file="EditProviderDetailsModal.tsx">
                {<>
                    <Typography>
                      You are about to change the provider details.
                    </Typography>
                    <Typography>
                      {"If you didn't mean this action, please click 'Cancel'."}
                    </Typography>
                    <Typography sx={{
                  mt: '1.5rem'
                }}>
                      Provide the change reason:
                    </Typography>
                    <TextField value={editProviderFormData.reason} label="Type Reason" required={true} variant="filled" onChange={e => updateEditProviderDetailsForm('reason', e.target.value)} />
                  </>}
                <FormControlLabel sx={{
                mt: '1.5rem'
              }} control={<Checkbox data-test="attest-checkbox" checked={editProviderFormData.acceptedTos} onChange={e => updateEditProviderDetailsForm('acceptedTos', e.target.checked)} />} label="I attest that this action meets policy" data-sentry-element="FormControlLabel" data-sentry-source-file="EditProviderDetailsModal.tsx" />
              </FormGroup>
            </Box>{' '}
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        justifyContent: 'space-between',
        borderTop: 'solid 1px rgba(0,0,0,.2)',
        padding: 2
      }} data-sentry-element="DialogActions" data-sentry-source-file="EditProviderDetailsModal.tsx">
          <Button onClick={() => setEditProviderDetailsModalVisible(false)} disabled={loading} data-sentry-element="Button" data-sentry-source-file="EditProviderDetailsModal.tsx">
            Cancel Change
          </Button>
          <Button onClick={onSubmitEditProviderDetailsBtnClick} color="error" data-test="submit-change-btn" disabled={!isValid(editProviderFormData)} variant="contained" startIcon={isEditingProviderDetails && <CircularProgress size={14} color="inherit" />} data-sentry-element="Button" data-sentry-source-file="EditProviderDetailsModal.tsx">
            Submit Change
          </Button>
        </DialogActions>
      </form>
    </Dialog>;
};
export { EditProviderDetailsModal };